<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字：">
                        <el-input v-model='filters.name' placeholder="请输入姓名/手机号/身份证号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="是否变更考试项目：">
                        <el-select v-model="filters.isChangeExamProject" clearable>
                            <el-option
                                v-for="item in selectArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否变更培训机构：">
                        <el-select v-model="filters.isChangeOrganization" clearable>
                            <el-option
                                v-for="item in selectArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="变更日期：">
                        <el-date-picker
                            v-model="filters.changeTimer"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
            :data="listData"
            border
            v-loading="listLoading"
            style="width: 100%; margin-top: 20px"
        >
            <!-- <el-table-column prop="index" label="序号" width="50" align="center"></el-table-column> -->
            <el-table-column prop="Name" label="姓名" width="100" align="center"></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号" width="120" align="center"></el-table-column>
            <el-table-column prop="CardNo" label="身份证号" align="center" min-width="130"></el-table-column>
            <el-table-column prop="ChangeTime" label="变更日期" align="center" :formatter="formatCreateTime" min-width="100"></el-table-column>
            <el-table-column prop="" label="状态" width="90" align="center">
                <template>
                    <div class="oriSty1">原始</div>
                    <div class="afterSty1">变更后</div>
                </template>
            </el-table-column>
            <el-table-column prop="TradeName" min-width="120" label="行业（场所）" align="center">
                <template scope="scope">
                    <div class="oriSty2">{{scope.row.TradeName}}</div>
                    <div :class="(scope.row.AfterTradeName && (scope.row.TradeName != scope.row.AfterTradeName)) ? 'afterSty2 warningSty' : 'afterSty2'">{{(scope.row.AfterTradeName ? scope.row.AfterTradeName : scope.row.TradeName)}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="StationName" min-width="120" label="岗位（人群）" align="center">
                <template scope="scope">
                    <div class="oriSty3">{{scope.row.StationName}}</div>
                    <div :class="(scope.row.AfterStationName && (scope.row.StationName != scope.row.AfterStationName)) ? 'afterSty3 warningSty' : 'afterSty3'">{{(scope.row.AfterStationName ? scope.row.AfterStationName : scope.row.StationName)}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="AgencyInfoName" width="200" label="培训机构" align="center">
                <template scope="scope">
                    <div class="oriSty3">{{scope.row.AgencyInfoName}}</div>
                    <div :class="(scope.row.AfterAgencyInfoName && (scope.row.AgencyInfoName != scope.row.AfterAgencyInfoName)) ? 'afterSty3 warningSty' : 'afterSty3'">{{(scope.row.AfterAgencyInfoName ? scope.row.AfterAgencyInfoName : scope.row.AgencyInfoName)}}</div>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getLearnChangeListPage } from '../../api/api'
import util from "../../../util/date";
import Qs from 'qs'
export default {
    components: {Toolbar},
    data(){
        return {
            filters: {
                name: "",
                isChangeExamProject: "",
                isChangeOrganization: "",
                changeTimer: "",
            },
            buttonList: [],
            listData: [],
            listLoading: false,
            selectArr: [
                {value:true,name:'是'},
                {value:false,name:'否'},
            ],
            currentData: '',
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getTableDataFun() {
            this.pages.pageIndex = 1
            this.getListData();
        },
        // 获取分页列表
        getListData() {
            var _this = this
            var params = {
                keyWord: this.filters.name,
                isChangeEnroll: this.filters.isChangeExamProject,
                isChangeAgency: this.filters.isChangeOrganization,
                changeStartDate: this.formatCreateTime1(this.filters.changeTimer[0]),
                changeEndDate: this.formatCreateTime1(this.filters.changeTimer[1]),
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.listLoading = true;
            getLearnChangeListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    _this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.listData = result.Response.Data
                }
            })
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.currentData = val
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val
            this.getListData();
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.ChangeTime || row.ChangeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.ChangeTime), "yyyy-MM-dd");
        },
        formatCreateTime1: function(row) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        },
    },
    mounted() {
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
    .ediColor>>>.el-checkbox__input.is-disabled+span.el-checkbox__label{
        color #606266!important;
    }
    .oriSty1 {
        width: 83px;
        margin-left: -10px;
    }
    .afterSty1 {
        border-top: 1px solid #EBEEF5;
        width: 83px;
        margin-left: -10px;
        padding-top: 5px;
        margin-top: 5px;
    }
    .oriSty2 {
        width: 113px;
        margin-left: -10px;
    }
    .afterSty2 {
        border-top: 1px solid #EBEEF5;
        width: 113px;
        margin-left: -10px;
        padding-top: 5px;
        margin-top: 5px;
    }
    .oriSty3 {
        width: 193px;
        margin-left: -10px;
    }
    .afterSty3 {
        border-top: 1px solid #EBEEF5;
        width: 193px;
        margin-left: -10px;
        padding-top: 5px;
        margin-top: 5px;
    }
    .warningSty {
        color: #F56C6C;
    }
</style>